<template>
	<div>
		<CCol md="12" class="mb-4">
			<CRow align-horizontal="end">
				<router-link
					:to="{ name: 'VariantInfoCreate' }"
					class="link-button"
				>
					<CButton class="transparent ml-3" color="secondary">
						<CIcon class="icon-left" name="cil-plus" />
						Create variant info
					</CButton>
				</router-link>
			</CRow>
		</CCol>
		<CRow>
			<CCol class="d-flex align-items-start">
				<form
					class="search-form flex-fill"
					@submit.prevent="handleSearch"
				>
					<CInput
						v-model.trim="queryParams.q"
						type="text"
						placeholder="Search by variant info name or ID"
					>
						<template #prepend-content>
							<button class="btn-search" type="submit">
								<FontAwesomeIcon :icon="['far', 'search']" />
							</button>
						</template>
					</CInput>
				</form>
				<BaseFilterButton
					:is-open="isShowFilter"
					:has-filter="hasFilter"
					class="ml-3"
					data-id="filter-button"
					@onClick="toggleFilter"
				/>
			</CCol>
		</CRow>
		<CRow>
			<CCol>
				<transition name="fade">
					<div
						v-if="isShowFilter"
						class="bg-gray-100 rounded-sm pt-3 px-3 mb-3"
						data-id="show-filter-box"
					>
						<CRow>
							<CCol md="3">
								<BaseDropDown
									v-model="filter.status"
									:options="VARIANT_INFO_STATUS_OPTIONS"
									:allow-empty="false"
									:searchable="false"
									label="name"
									label-drop-down="Status"
									track-by="value"
									class="select-custom"
									@input="handleStatus"
								/>
							</CCol>
						</CRow>
					</div>
				</transition>
			</CCol>
		</CRow>
		<CRow>
			<CCol>
				<BaseTable
					:is-loading="isLoading"
					:pagination="{
						pages: pagination.lastPage,
						activePage: pagination.currentPage
					}"
					:fields="VARIANT_INFO_TABLE_FIELDS"
					:items="list.data"
					striped
					hover
					fixed
					clickable-rows
					link-to="VariantInfoEdit"
					class="table-custom table-custom-link"
					@onPaginationClick="handlePageChange"
				>
					<template #no-items-view>
						<div class="empty-table-element">
							<p class="subtitle">
								{{ $t("global.searchNotFound", { field: "variant info" }) }}
							</p>
						</div>
					</template>
					<template #isActive="{item}">
						<CBadge
							class="badge-status"
							:color="VARIANT_INFO_STATUSES[item].class"
						>
							{{ VARIANT_INFO_STATUSES[item].title }}
						</CBadge>
					</template>
				</BaseTable>
			</CCol>
		</CRow>
	</div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { cleanObject, randomSearchString, hasSearchFilter } from '../assets/js/helpers';
import {
	VARIANT_INFO_STATUSES,
	VARIANT_INFO_STATUS_OPTIONS,
	VARIANT_INFO_TABLE_FIELDS,
} from '../enums/variantInfo';

export default {
	name: 'ProductFamilyList',
	data() {
		return {
			VARIANT_INFO_STATUSES,
			VARIANT_INFO_STATUS_OPTIONS,
			VARIANT_INFO_TABLE_FIELDS,

			isShowFilter: false,
			queryParams: {
				q: this.$route.query.q || null,
				is_active: this.$route.query.is_active,
				page: Number(this.$route.query.page) || null,
				r: randomSearchString(),
			},
			filter: {
				status: this.getSelectedValue(this.$route.query.is_active, VARIANT_INFO_STATUS_OPTIONS),
			},
		};
	},
	computed: {
		...mapState('variantInfo', {
			list: 'list',
		}),

		isLoading() {
			return this.list.isLoading;
		},
		pagination() {
			return this.list.meta;
		},
		hasFilter() {
			return hasSearchFilter(this.queryParams);
		},
	},
	async created() {
		this.isShowFilter = this.hasFilter;
		await this.getVariantInfoList(this.queryParams);
	},
	methods: {
		...mapActions({
			getVariantInfoList: 'variantInfo/getVariantInfoList',
		}),
		toggleFilter() {
			this.isShowFilter = !this.isShowFilter;
		},
		updateUrlParams() {
			const query = cleanObject(JSON.parse(JSON.stringify(this.queryParams)));
			this.$router.push({ query });
		},
		handleSearch() {
			this.queryParams.page = null;
			this.updateUrlParams();
		},
		handleStatus({ value }) {
			this.queryParams.page = null;
			this.queryParams.is_active = value;
			this.updateUrlParams();
		},
		handlePageChange(page) {
			this.queryParams.page = page;
			this.updateUrlParams();
		},
		getSelectedValue(selectedValue = null, options = []) {
			const optionValues = Object.values(options);
			const found = ({ value }) => value === selectedValue;

			return (
				optionValues.find(found) || {
					value: null,
					label: null,
				}
			);
		},
	},
};
</script>

<style lang="scss" scoped>
	.btn-search {
		background: transparent;
		box-shadow: none;
		border: none;
		padding: 0;
	}
</style>
