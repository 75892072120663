export const VARIANT_INFO_STATUSES = [
	{ title: 'Inactive', class: 'inactive' },
	{ title: 'Active', class: 'success' },
];

export const VARIANT_INFO_STATUS_VALUES = {
	ACTIVE: 1,
	INACTIVE: 0,
};

export const VARIANT_INFO_STATUS_OPTIONS = [
	{ name: 'All Status', value: null },
	{ name: 'Active', value: VARIANT_INFO_STATUS_VALUES.ACTIVE },
	{ name: 'Inactive', value: VARIANT_INFO_STATUS_VALUES.INACTIVE },
];

export const VARIANT_INFO_TABLE_FIELDS = [
	{ key: 'id', label: 'ID', class: 'col-id th-mw-40' },
	{ key: 'name', label: 'Variant info name', class: 'col-name th-mw-100' },
	{ key: 'isActive', label: 'Status', class: 'col-status th-mw-40' },
];
